import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import { i18n } from "../translate/i18n";

import BR from "../assets/flags/br.svg";
import US from "../assets/flags/us.svg";
import logo from "../assets/logo.png";

const logoStyle = {
  width: "5rem",
  height: "5rem",
  cursor: "pointer",
};

const FlagIcon = styled("img")({
  width: 24,
  height: 24,
});

function AppAppBar({ mode }) {
  const I18N_STORAGE_KEY = "i18nextLng";
  const [open, setOpen] = React.useState(false);
  const [language, languageSet] = React.useState(
    localStorage.getItem(I18N_STORAGE_KEY)
  );
  const [flag, setFlag] = React.useState(BR);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  const handleSelectChange = () => {
    const newLanguageSet = language === "pt-BR" ? "en-US" : "pt-BR";
    localStorage.setItem(I18N_STORAGE_KEY, newLanguageSet);
    languageSet(newLanguageSet);
    window.location.reload();
  };

  React.useEffect(() => {
    if (language === "pt-BR") {
      setFlag(US);
    } else {
      setFlag(BR);
    }
  }, [language]);

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor: "#F5F5F5",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <div
                style={{
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={logo} style={logoStyle} alt="logo of sitemark" />
              </div>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Tooltip title={i18n.t("header.tooltips.feature")}>
                  <MenuItem
                    onClick={() => scrollToSection("features")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="#021F3B">
                      {i18n.t("header.feature")}
                    </Typography>
                  </MenuItem>
                </Tooltip>
                <Tooltip title={i18n.t("header.tooltips.testimonials")}>
                  <MenuItem
                    onClick={() => scrollToSection("testimonials")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="#021F3B">
                      {i18n.t("header.testimonials")}
                    </Typography>
                  </MenuItem>
                </Tooltip>
                <Tooltip title={i18n.t("header.tooltips.highlights")}>
                  <MenuItem
                    onClick={() => scrollToSection("highlights")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="#021F3B">
                      {i18n.t("header.highlights")}
                    </Typography>
                  </MenuItem>
                </Tooltip>
                <Tooltip title={i18n.t("header.tooltips.faq")}>
                  <MenuItem
                    onClick={() => scrollToSection("faq")}
                    sx={{ py: "6px", px: "12px" }}
                  >
                    <Typography variant="body2" color="#021F3B">
                      {i18n.t("header.faq")}
                    </Typography>
                  </MenuItem>
                </Tooltip>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <IconButton onClick={handleSelectChange}>
                <FlagIcon src={flag} alt="flag-img" />
              </IconButton>
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <IconButton onClick={handleSelectChange}>
                        <FlagIcon src={flag} alt="flag-img" />
                      </IconButton>
                    </Box>
                  </Box>
                  <MenuItem onClick={() => scrollToSection("features")}>
                    {i18n.t("header.feature")}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("highlights")}>
                    {i18n.t("header.highlights")}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("testimonials")}>
                    {i18n.t("header.testimonials")}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("faq")}>
                    {i18n.t("header.faq")}
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-up/"
                      target="_blank"
                      sx={{ width: "100%" }}
                    >
                      {i18n.t("header.buttons.signUp")}
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-in/"
                      target="_blank"
                      sx={{ width: "100%" }}
                    >
                      {i18n.t("header.buttons.signIn")}
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
